import * as React from 'react';

import BackgroundAudio from '../../components/BackgroundAudio/BackgroundAudio';
import PageWithTitle from '../../components/PageWithTitle';
import SliderSwitch from '../../components/sliderswitch/SliderSwitch';
import Storage from '../../data/Storage';
import More from './More';
import MoreItem from './MoreItem';

export default class Settings extends MoreItem {
  public static get backgroundAudio(): boolean {
    const itemValue: string | null = Storage.getItem('backgroundAudio');
    let result: boolean;

    if (itemValue === null) {
      result = false;
    } else {
      result = JSON.parse(itemValue) as boolean;
    }

    return result;
  }

  public static set backgroundAudio(value: boolean) {
    Storage.setItem('backgroundAudio', JSON.stringify(value));
  }

  constructor(props: any) {
    More.currentMenuItem = 'Settings';

    super(props);
  }

  public render() {
    return (
      <div className="Settings">
        <PageWithTitle title="Settings">
          <dl>
            <dt>Background Audio</dt>
            <dd>
              <SliderSwitch
                isChecked={Settings.backgroundAudio}
                propertyName="backgroundAudio"
                callback={this.onChangeHandler}
              />
            </dd>
          </dl>
        </PageWithTitle>
      </div>
    );
  }

  protected onChangeHandler(isChecked: boolean, propertyName: string) {
    Settings.backgroundAudio = isChecked;

    BackgroundAudio.setAutoPlay(isChecked);
  }
}
