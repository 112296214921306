import * as React from 'react';

import { IBackgroundScene } from '../config/AppConfig';
import Config from '../config/Config';

import './BackgroundScene.css';

export interface IBackgroundSceneProps {
  backgroundScene: IBackgroundScene | null;
  className?: string;
}

export default class BackgroundScene extends React.Component<IBackgroundSceneProps, any> {
  public render() {
    return (
      <div
        className={
          'BackgroundScene ' + (this.props.className === undefined ? '' : this.props.className)
        }
      >
        {(() => {
          let background;

          if (this.props === undefined || this.props.backgroundScene === null) {
            background = null;
          } else {
            switch (this.props.backgroundScene.type) {
              case 'image':
                let stretchClass = '';
                if (this.props.backgroundScene.stretch) {
                  stretchClass = ' stretched';
                }
                background = (
                  /* For fetch to work (downloading assets for offline use) we need
                    to add crossOrigin="anonymous". Otherwise image in browser cache will not have
                    header "Access-Control-Allow-Origin" */

                  <img
                    crossOrigin="anonymous"
                    className={this.props.className + stretchClass}
                    src={Config.adjustPath(this.props.backgroundScene.imageUrl)}
                    alt={Config.adjustPath(this.props.backgroundScene.imageUrl)}
                    onError={this.onImageError}
                  />
                );
                break;
              case 'video':
                background = (
                  <video
                    className={this.props.className}
                    muted={true}
                    autoPlay={true}
                    loop={true}
                    playsInline={true}
                    poster={Config.adjustPath(this.props.backgroundScene.imageUrl)}
                  >
                    <source
                      src={Config.appPath + this.props.backgroundScene.videoUrl}
                      type="video/mp4"
                    />
                  </video>
                );
                break;
            }
          }
          return background;
        })()}
      </div>
    );
  } // render()

  protected onImageError(event: React.SyntheticEvent<HTMLImageElement, Event>) {
    (event.target as HTMLImageElement).onerror = null;

    (event.target as HTMLImageElement).style.display = 'none';
  }
}
