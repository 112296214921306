import * as React from 'react';

import './Asset.css';

import API from '../../api/API';
import IAsset from '../../api/IAsset';
import IPage from '../../api/IPage';
import BackgroundScene from '../../components/BackgroundScene';
import Lang from '../../components/Lang';
import LoadingIcon from '../../components/LoadingIcon/LoadingIcon';
import MediaControl from '../../components/MediaControl';
import NarratorImage from '../../components/NarratorImage';
import Config from '../../config/Config';
import AssetFileOperations from '../../data/AssetFileOperations';
import Data from '../../data/Data';
import FileStorage from '../../data/FileStorage/FileStorage';
import Html from '../../helpers/Html';
import Url from '../../helpers/Url';
import PageWithTopNavBar from '../PageWithTopNavBar';
import MediaElement from './MediaElement';

import iPhoneShareIcon from '../../assets/icons/iphone_share.svg';

const Window: any = window;
Window.resolveLocalFileSystemURL =
  Window.resolveLocalFileSystemURL || Window.webkitResolveLocalFileSystemURL;

interface IState {
  asset: IAsset | undefined;
  page?: IPage;
  programMedia: IAsset[];
}
export default class Asset extends React.Component<any, IState> {
  public static readonly NON_SELECTED_LIKE = 'fal fa-thumbs-up';
  public static readonly SELECTED_LIKE = 'fas fa-thumbs-up';
  public static readonly DOWNLOADED = 'fas fa-cloud';
  public static readonly NOT_DOWNLOADED = 'fal fa-cloud-download';

  public static isMaximized(): boolean | null {
    let result: boolean | null = null;

    const asset: HTMLElement | null = document.getElementById('Asset');

    if (asset !== null && asset!.className.indexOf('minimize') === -1) {
      result = true;
    } else if (asset !== null) {
      result = false;
    }

    return result;
  }

  public static clearAsset() {
    Asset.self.setState({ asset: undefined });

    const offline = Html.replaceSpacesWithDashes(Data.OFFLINE_MENU_ITEM);

    // TODO: analyze why I need this:
    if (
      PageWithTopNavBar.topNavBarIndex[Asset.self.state.page!.page_name] === 'My' ||
      PageWithTopNavBar.topNavBarIndex[Asset.self.state.page!.page_name] === offline
    ) {
      PageWithTopNavBar.updateAssetList();
    }
  }

  public static playNextAsset() {
    const index = Asset.self.state.programMedia.findIndex((asset: IAsset, index: number) => {
      let result: boolean;
      if (asset.asset_id === Asset.self.state.asset!.asset_id) {
        result = true;
      } else {
        result = false;
      }

      return result;
    });

    if (
      Asset.self.state.programMedia.length === index + 1 ||
      Asset.self.state.programMedia[index + 1].asset_type === 'program' ||
      Asset.self.state.programMedia[index + 1].media!.media_type === 'text'
    ) {
      Asset.clearAsset();
    } else {
      Asset.setAssetId(
        Asset.self.state.programMedia[index + 1].asset_id,
        Asset.self.state.page!,
        Asset.self.state.programMedia
      );
    }
  }

  public static setAssetId(
    assetId: number,
    page: IPage /* for Like and downloaded file name */,
    programMedia: IAsset[] = []
  ) {
    console.log('Asset.setAssetId(). assetId=' + assetId);
    if (Asset.self.state.asset === undefined || Asset.self.state.asset.asset_id !== assetId) {
      if (Data.assetList[assetId] !== undefined) {
        console.log('Data.assetList[assetId]=', Data.assetList[assetId]);
        Asset.self.setState({ asset: Data.assetList[assetId], page, programMedia });
      } else {
        API.getAsset(assetId).then(response => {
          Asset.self.setState({ asset: response, page, programMedia });
        });
      }
    }
  }

  public static minimize() {
    Asset.self.minimized = true;

    const asset: HTMLElement | null = document.getElementById('Asset');
    asset!.className += ' minimize';
    asset!.addEventListener('click', Asset.maximize);

    document.getElementById('MediaControl')!.className = 'MediaControl minimize';
  }

  public static maximize(event: any) {
    if (event.target.nodeName !== 'I') {
      // if not media control button clicked
      Asset.self.minimized = false;

      const asset: HTMLElement | null = document.getElementById('Asset');
      asset!.className = asset!.className.replace(' minimize', '');
      asset!.removeEventListener('click', Asset.maximize);

      document.getElementById('MediaControl')!.className = 'MediaControl';

      MediaControl.updateProgressBar();
    }
  }

  public static errorHandling() {
    LoadingIcon.hide();
    document.getElementById('asset-error')!.style.visibility = 'visible';
  }

  public static getAssetType() {
    return Asset.self.state.asset?.media?.media_type;
  }

  protected static self: Asset;
  protected minimized: boolean = false;

  constructor(props: any) {
    super(props);

    Asset.self = this; // to use it in ImageItem (when clicking on thumbnail)

    this.state = {
      asset: undefined, // will be assigned Data.assetList[assetId]
      page: undefined,
      programMedia: []
    };

    this.downloadHandler = this.downloadHandler.bind(this);
    this.like = this.like.bind(this);
  }

  public render() {
    let category = '/My';
    if (this.state.page) {
      category = this.state.page.page_name + category;
    }

    const isLiked = Data.isInAssetListPerCategory(category, this.state.asset!);

    return (
      this.state.asset !== undefined && (
        <div
          id="Asset"
          key={this.state.asset.asset_id} /*to prevent caching*/
          className={
            'Asset ' + this.state.asset.media?.media_type + (this.minimized ? ' minimize' : '')
          }
          onClick={this.minimized ? Asset.maximize : undefined}
        >
          {/*this.state.asset.asset_type === API.Asset.AUDIO*/ true ? (
            <BackgroundScene
              backgroundScene={{
                type: 'image',
                imageUrl: this.state.asset.image_background
              }}
              className="background"
            />
          ) : (
            <div className="background backgroundColorBlack" />
          )}

          <header>
            {this.state.asset.media!.media_type !== API.Asset.TEXT ? (
              <span className="minimizeIcon">
                <i className="far fa-chevron-down minimize" onClick={Asset.minimize} />
              </span>
            ) : (
              <span className="backIcon">
                <i className="fal fa-chevron-circle-left" onClick={Asset.clearAsset} />
              </span>
            )}

            <span className="downloadIcon" onClick={this.downloadHandler}>
              <i className={this.isDownloaded ? Asset.DOWNLOADED : Asset.NOT_DOWNLOADED} />
              <i className="fal fa-check" />
            </span>

            <NarratorImage narrator={this.state.asset.narrator} />
          </header>

          <div className="descriptionSection">
            <header>
              <Lang str={this.state.asset.asset_name} />
            </header>

            <section>
              <Lang str={this.state.asset.asset_description} />
            </section>
            {this.state.asset.narrator !== null && (
              <footer>
                <dl>
                  <dt>NARRATOR</dt>
                  <dd>{this.state.asset.narrator.user_fullname}</dd>
                </dl>
              </footer>
            )}
          </div>

          <div id="asset-error">Error loading media</div>

          <footer>
            <span className={'likeIcon' + (isLiked ? ' selected' : '')} onClick={this.like}>
              <i className={isLiked ? Asset.SELECTED_LIKE : Asset.NON_SELECTED_LIKE} />
            </span>
            {Config.isIPhone ? (
              <span className="shareIcon" onClick={this.share}>
                <img
                  src={Config.appPath + iPhoneShareIcon}
                  alt={Config.appPath + iPhoneShareIcon}
                  className="centered"
                />
              </span>
            ) : (
              <span className="shareIcon" onClick={this.share}>
                <i className="fas fa-share-alt" />
              </span>
            )}

            {this.state.asset.media!.media_type !== API.Asset.TEXT && (
              <div>
                <MediaElement
                  mediaTagId="asset-media"
                  asset={this.state.asset}
                  onErrorCallback={this.onError}
                />

                <MediaControl
                  mediaTagId="asset-media"
                  minimized={this.minimized}
                  alreadyPlaying={true}
                />
              </div>
            )}
          </footer>
        </div>
      )
    );
  } // render()

  public onError(event: React.SyntheticEvent<HTMLAudioElement, Event>) {
    console.log(event);
    console.log(event.type);
    // console.log((event as any).message);
    console.log(event.nativeEvent);
    // console.log(event.nativeEvent.target);
    // console.log((event.nativeEvent as any).error);
    // console.log(message, source, error);
    Asset.errorHandling();
  }

  protected get isDownloaded() {
    const offline = Html.replaceSpacesWithDashes(Data.OFFLINE_MENU_ITEM);

    return Data.isInAssetListPerCategory(
      this.state.page!.page_name + '/' + offline,
      this.state.asset!
    );
  }

  protected like(event: React.MouseEvent<HTMLSpanElement>) {
    const iElement = document.querySelector('#Asset span.likeIcon i');
    const spanElement = document.querySelector('#Asset span.likeIcon');

    if (spanElement!.className.includes('selected')) {
      spanElement!.className = 'likeIcon';
      iElement!.className = Asset.NON_SELECTED_LIKE;

      Data.removeFromAssetListPerCategory(
        this.state.page!.page_name + '/My',
        Asset.self.state.asset!
      );
    } else {
      spanElement!.className = 'likeIcon selected';
      iElement!.className = Asset.SELECTED_LIKE;

      Data.addToAssetListPerCategory(this.state.page!.page_name + '/My', Asset.self.state.asset!);
    }
  }

  protected share() {
    console.log('share()');
    console.log(Asset.self.state.asset!.asset_name);
    const myNavigator = window.navigator as any;

    if (myNavigator.share) {
      let description: string = (Asset.self.state.asset!.asset_description || '').trim();

      if (description !== '') {
        description += '\n\n';
      }

      myNavigator
        .share({
          title: 'Sent from Sufi App',
          text: '"' + Asset.self.state.asset!.asset_name + '"\n\n' + description,
          url: 'https://share.sufiapp.com/' + Asset.self.state.asset!.asset_id
        })
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch((err: any) => {
          console.log(`Couldn't share because of`, err.message);
        });
    } else {
      console.log('Web Share API is not supported');
      alert('Web Share API is not supported');
    }
  }

  protected downloadHandler() {
    if (this.isDownloaded) {
      this.deleteAssetLocalCopy();
    } else {
      this.downloadAssetLocalCopy();
    }
  } // downloadHandler()

  protected deleteAssetLocalCopy() {
    if (window.confirm('Delete local copy of "' + Asset.self.state.asset!.asset_name + '"?')) {
      const offline = Html.replaceSpacesWithDashes(Data.OFFLINE_MENU_ITEM);

      const offlineAsset: IAsset | null = Data.getAssetFromAssetListPerCategory(
        this.state.page!.page_name + '/' + offline,
        Asset.self.state.asset!.asset_id
      );

      const fileList = AssetFileOperations.getFileList([offlineAsset!]);
      fileList.forEach((fileName: string | null, index: number) => {
        if (fileName !== null) {
          FileStorage.deleteFile(fileName);
        }

        if (index === fileList.length - 1) {
          Data.removeFromAssetListPerCategory(
            this.state.page!.page_name + '/' + offline,
            offlineAsset!
          );
          const iElement = document.querySelector('#Asset span.downloadIcon i');
          iElement!.className = Asset.NOT_DOWNLOADED;
        }
      });
    }
  }

  protected downloadAssetLocalCopy() {
    LoadingIcon.show();

    this.downloadFiles(this.state.asset!, (localAsset: IAsset) => {
      LoadingIcon.hide();

      const offline = Html.replaceSpacesWithDashes(Data.OFFLINE_MENU_ITEM);
      Data.addToAssetListPerCategory(this.state.page!.page_name + '/' + offline, localAsset);

      const iElement = document.querySelector('#Asset span.downloadIcon i');
      iElement!.className = Asset.DOWNLOADED;
    });
  }

  protected downloadFiles(asset: IAsset, successCallback: (localAsset: IAsset) => void): void {
    const fileNamePrefix = this.state.page!.page_name + '_';
    const fileList: { [key: string]: string } = this.buildFileList(
      [asset.image_thumbnail, asset.image_background, asset.media!.media_url],
      fileNamePrefix
    );

    const localAsset = {} as IAsset;
    Object.assign(localAsset, asset);

    localAsset.media!.media_url = FileStorage.prefix + fileList[asset.media!.media_url!];
    if (asset.image_thumbnail !== null) {
      localAsset.image_thumbnail = FileStorage.prefix + fileList[asset.image_thumbnail!];
    }
    if (asset.image_background !== null) {
      localAsset.image_background = FileStorage.prefix + fileList[asset.image_background!];
    }

    console.log(asset);

    FileStorage.downloadManyFiles(fileList, () => successCallback(localAsset), this.errorCallback);
  }

  protected buildFileList(fileListArr: any[], fileNamePrefix: string = '') {
    const fileList: { [key: string]: string } = {};
    for (const index in fileListArr) {
      if (fileListArr[index] !== null) {
        fileList[fileListArr[index]] = fileNamePrefix + Url.getFileName(fileListArr[index]);
      }
    }

    return fileList;
  }

  protected errorCallback(message: string, error: Error): void {
    console.log(message, error);

    LoadingIcon.hide();

    if (error.name === 'FetchError') {
      alert('Error fetching remote files');
    } else if (error.name === 'FileSystemError') {
      alert('File System API is not supported');
    } else if (error.name === 'FileOperationError') {
      alert('File Operation error');
    } else {
      alert('Error: ' + message);
    }
  }
}
