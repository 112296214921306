export default class Time {
  public static secondsToStr(timeInSeconds: number): string {
    const minutes = Math.floor(timeInSeconds / 60);
    let seconds: string = String(Math.floor(timeInSeconds - minutes * 60));

    if (seconds.length < 2) {
      seconds = '0' + seconds;
    }

    return minutes + ':' + seconds;
  }

  public static roundMinites(durationInSeconds: number) {
    return Math.ceil(durationInSeconds / 60);
  }
}
