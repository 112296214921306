import Storage from '../data/Storage';

import Dr_Nour_shaykh_Hisham_shaykh_Nazim from '../assets/images/background//Dr_Nour_shaykh_Hisham_shaykh_Nazim.jpeg';
import shaykh_Nazim_saying from '../assets/images/background/shaykh_Nazim_saying.jpeg';
import shaykh_Hisham_and_shaykh_Nazim from '../assets/images/background/shaykh_Hisham_and_shaykh_Nazim.jpeg';
import shaykh_Hisham_pointing_finger_full_height from '../assets/images/background/shaykh_Hisham_pointing_finger_full_height.jpeg';
import shaykh_Hisham_with_prophet_hair from '../assets/images/background/shaykh_Hisham_with_prophet_hair.jpeg';
import shaykh_Hisham_pictogram from '../assets/images/background/shaykh_Hisham_pictogram.jpeg';
import shaykh_Nazim_pictogram from '../assets/images/background/shaykh_Nazim_pictogram.jpeg';

import StreamImage from '../assets/videos/background/stream.jpg';
import StreamVideo from '../assets/videos/background/stream.mp4';

export interface IBackgroundScene {
  type: string;
  imageUrl: string | null;
  videoUrl?: string;
  title?: string;
  stretch?: boolean;
}

export default class AppConfig {
  public static transparentImage =
    'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw== ';

  // public static topBanner: { [key: string]: string } = {
  //   Sleep: TopBannerImageMusic,
  //   Meditate: TopBannerImageMeditate,
  //   Music: TopBannerImageMusic
  // };

  // public static backgroundColor: { [key: string]: string } = {
  //   Sleep: 'backgroundColorSleep',
  //   Meditate: 'backgroundColorMeditate',
  //   Music: 'backgroundColorMusic'
  // };

  public static backgroundSceneList: IBackgroundScene[] = [
    {
      type: 'image',
      imageUrl: Dr_Nour_shaykh_Hisham_shaykh_Nazim,
      title: 'Awliya'
    },
    {
      type: 'image',
      imageUrl: shaykh_Hisham_with_prophet_hair,
      title: "Prophet's hair"
    },
    {
      type: 'image',
      imageUrl: shaykh_Hisham_pointing_finger_full_height,
      title: '"I am really with you"'
    },
    {
      type: 'image',
      imageUrl: shaykh_Hisham_and_shaykh_Nazim,
      title: 'Shaykh Hisham and Shaykh Nazim'
    },
    {
      type: 'image',
      imageUrl: shaykh_Nazim_saying,
      title: '"I am enough for him"'
    },
    {
      type: 'image',
      imageUrl: shaykh_Hisham_pictogram,
      title: 'shaykh Hisham pictogram'
    },
    {
      type: 'image',
      imageUrl: shaykh_Nazim_pictogram,
      title: 'shaykh Nazim pictogram'
    },
    {
      type: 'video',
      imageUrl: StreamImage,
      videoUrl: StreamVideo,
      title: 'Stream'
    }
  ];

  public static pageBackgroundScene: { [key: string]: number | null } = {
    Home: 0,
    Sleep: null,
    Meditate: null,
    Music: null
  };

  public static init() {
    if (Storage.getItem('homeBackgroundScene') !== null) {
      AppConfig.pageBackgroundScene['Home'] = JSON.parse(Storage.getItem('homeBackgroundScene')!);

      if (AppConfig.pageBackgroundScene['Home']! >= AppConfig.backgroundSceneList.length) {
        AppConfig.pageBackgroundScene['Home'] = 0;
      }
    }
  }
}

AppConfig.init();
