import * as React from 'react';

import MyHistory from '../../components/MyHistory';
import Url from '../../helpers/Url';
import More from './More';

export default abstract class MoreItem extends React.Component<any, any> {
  // should be set in child classes. We can't use child class name (through this.constructor.name) because in production build original names will be changed.
  protected componentName?: string;

  constructor(props: any) {
    super(props);

    if (More.currentMenuItem !== Url.lastChain()) {
      MyHistory.replace(MyHistory.location.pathname + '/' + More.currentMenuItem);
    }
  }
}
