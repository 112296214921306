import * as React from 'react';

import PageWithTitle from '../../components/PageWithTitle';
import Config from '../../config/Config';
import More from './More';
import MoreItem from './MoreItem';

export default class System extends MoreItem {
  constructor(props: any) {
    More.currentMenuItem = 'System';

    super(props);

    let sizeItem: number | null;
    if (localStorage.getItem('size') !== null) {
      sizeItem = parseInt(localStorage.getItem('size') || '');
    } else {
      sizeItem = null;
    }

    this.state = {
      usedLocalStorage: Math.floor(new Blob([JSON.stringify(localStorage)]).size / 1000),

      availableLocalStorage: sizeItem
    };
  }

  public render() {
    return (
      <div className="System">
        <PageWithTitle title="System Info">
          <dl>
            <div>
              <dt>Version</dt>
              <dd>{Config.version}</dd>
            </div>
            {/*
            <div>
              <dt>Backend</dt>
              <dd>{API.domain}</dd>
            </div>

            <div>
              <dt>Frontend</dt>
              <dd>
                {window.location.protocol + '//'}
                {window.location.hostname}
              </dd>
            </div>
*/}
            <div>
              <dt>User Agent</dt>
              <dd>{navigator.userAgent}</dd>
            </div>

            <div>
              <dt>LocalStorage Used</dt>
              <dd>{this.state.usedLocalStorage} KB</dd>
            </div>

            <div>
              <dt>LocalStorage Available</dt>
              <dd>{this.state.availableLocalStorage?.toLocaleString()} KB</dd>
            </div>
          </dl>
        </PageWithTitle>
      </div>
    );
  }

  public async componentDidMount() {
    setTimeout(() => {
      this.getMaxLocalStorageSize((maxSize: number) => {
        this.setState({ availableLocalStorage: maxSize });
      });
    }, 1);
  }

  protected async getMaxLocalStorageSize(successCallback: (maxSize: number) => void) {
    // from https://stackoverflow.com/questions/2989284/what-is-the-max-size-of-localstorage-values/25812530#25812530

    if (localStorage && !localStorage.getItem('size')) {
      var i = 0;
      try {
        // Test up to 10 MB
        for (i = 250; i <= 10000; i += 250) {
          localStorage.setItem('test', new Array(i * 1024 + 1).join('a'));
        }
      } catch (e) {
        localStorage.removeItem('test');
        const maxSize = i - 250;
        // localStorage.setItem('size',  maxSize.toString());

        successCallback(maxSize);
      }
    }
  }
}
