import * as React from 'react';

interface IProps {
  imageUrl: string;
  fallbackImageUrl?: string;
  title?: string;
}

export default class Image extends React.Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);

    this.onImageError = this.onImageError.bind(this);
  }

  public render() {
    return (
      this.props.imageUrl !== null && (
        /* For fetch to work (downloading assets for offline use) we need
          to add crossOrigin="anonymous". Otherwise image in browser cache will not have
          header "Access-Control-Allow-Origin" */
        <img
          crossOrigin="anonymous"
          className="RetinaImage"
          src={this.props.imageUrl}
          alt={this.props.imageUrl}
          onError={this.onImageError}
        />
      )
    );
  }

  protected onImageError(event: React.SyntheticEvent<HTMLImageElement, Event>) {
    const imageElement = event.target as HTMLImageElement;
    imageElement.onerror = null;

    if (this.props.fallbackImageUrl) {
      imageElement.srcset = this.props.fallbackImageUrl;
    } else {
      // if asset title was hidden then show it (otherwise can't figure out what kind of asset)
      imageElement.style.display = 'none';
      const titleElement = imageElement!.parentElement!.querySelector('.title.hide');
      if (titleElement !== null) {
        titleElement.className = titleElement.className.replace('hide', '');
      }
    }
  }
}
