import * as React from 'react';
import { NavLink } from 'react-router-dom';

import Html from '../helpers/Html';

export interface IHorizontalScrollingMenuProps {
  menuItemList: string[];
  menuItemActiveStyle: string;
  menuItemNonActiveStyle: string;
  urlPrefix: string;
}

export default class HorizontalScrollingMenu extends React.Component<
  IHorizontalScrollingMenuProps,
  any
> {
  public render() {
    return (
      <ul className="horizontal-scrolling-menu">
        {Object.entries(this.props.menuItemList).map((item, index) => {
          const url =
            this.props.urlPrefix + '/' + Html.replaceSpacesWithDashes(Html.strip(item[1]));

          return (
            <li key={index}>
              <NavLink
                key={index}
                to={'/' + url}
                exact={true}
                activeStyle={Html.cssToObj(this.props.menuItemActiveStyle)}
                style={Html.cssToObj(this.props.menuItemNonActiveStyle)}
                dangerouslySetInnerHTML={{ __html: item[1] }}
              />
            </li>
          );
        })}
      </ul>
    );
  }

  public componentDidUpdate() {
    const activeMenuItem = document.querySelector('ul.horizontal-scrolling-menu a.active');

    if (activeMenuItem !== null) {
      activeMenuItem!.scrollIntoView({ inline: 'center' });
    }
  }
}
