import IAsset from '../api/IAsset';
import IPage from '../api/IPage';
import Html from '../helpers/Html';
import Storage from './Storage';

export default class Data {
  public static assetList: IAsset[] = []; // key will be asset_id
  public static assetListPerCategory: { [key: string]: IAsset[] } = {}; // key will be "page_name/category_name"
  public static categoryListPerPage: { [key: string]: string[] } = {}; // key will be page_name

  // route can have dashes instead of spaces so we need reverse lookup
  public static categoryPerRoute: { [key: string]: string } = {}; // key will be "page_name/category_name"

  public static readonly OFFLINE_MENU_ITEM = 'Available Offline';

  public static setPagesAndCategories(pages: IPage[]) {
    Storage.setItem('pages', JSON.stringify(pages));

    pages.forEach((page: IPage) => {
      let categoryList = ['All', 'My <i class="fal fa-thumbs-up" />'];
      categoryList = categoryList.concat(page.categories!);
      categoryList = categoryList.concat([Data.OFFLINE_MENU_ITEM]);

      Data.setCategoryListForPage(page.page_name, categoryList);

      const offline = Html.replaceSpacesWithDashes(Data.OFFLINE_MENU_ITEM);
      const menuItemList = [page.page_name + '/My', page.page_name + '/' + offline];

      menuItemList.forEach(menuItem => {
        if (Storage.getItem(menuItem) !== null) {
          Data.assetListPerCategory[menuItem] = JSON.parse(Storage.getItem(menuItem)!);
        } else {
          Data.assetListPerCategory[menuItem] = [];
        }
      });
    });
  }

  public static getPagesAndCategories(): IPage[] {
    let result: IPage[];
    if (Storage.getItem('pages') !== null) {
      result = JSON.parse(Storage.getItem('pages')!);
    } else {
      result = [];
    }

    return result;
  }

  public static getPage(pageName: string): IPage | undefined {
    const result = Data.getPagesAndCategories().find(page => {
      return page.page_name === pageName;
    });

    return result;
  }

  public static setCategoryListForPage(pageName: string, categoryList: string[]): void {
    Data.categoryListPerPage![pageName] = categoryList;

    Object.entries(categoryList).forEach((item, index) => {
      const url = pageName + '/' + Html.replaceSpacesWithDashes(Html.strip(item[1]));

      Data.categoryPerRoute[url] = item[1];
    });
  }

  public static getCategoryListForPage(pageName: string): string[] {
    let result: string[];

    if (Data.categoryListPerPage[pageName] !== undefined) {
      result = Data.categoryListPerPage[pageName];
    } else {
      result = [];
    }

    return result;
  }

  public static addToAssetListPerCategory(key: string, asset: IAsset) {
    if (Data.assetListPerCategory[key] === undefined) {
      Data.assetListPerCategory[key] = [asset];
    } else {
      const index = Data.assetListPerCategory[key].findIndex(
        (assetItem: IAsset) => assetItem.asset_id === asset.asset_id
      );
      if (index === -1) {
        Data.assetListPerCategory[key].push(asset);
      }
    }

    Storage.setItem(key, JSON.stringify(Data.assetListPerCategory[key]));
  }

  public static setAssetListPerCategory(key: string, assetList: IAsset[]) {
    Data.assetListPerCategory[key] = assetList;

    Storage.setItem(key, JSON.stringify(Data.assetListPerCategory[key]));
  }

  public static removeFromAssetListPerCategory(key: string, asset: IAsset) {
    const mySet = new Set(Data.assetListPerCategory[key]);
    mySet.delete(asset);
    Data.assetListPerCategory[key] = Array.from(mySet);

    Storage.setItem(key, JSON.stringify(Data.assetListPerCategory[key]));
  }

  public static isInAssetListPerCategory(key: string, asset: IAsset) {
    let result: boolean;

    if (Data.assetListPerCategory[key] === undefined || asset === undefined) {
      result = false;
    } else if (
      Data.assetListPerCategory[key].findIndex(
        (assetItem: IAsset) => assetItem.asset_id === asset.asset_id
      ) === -1
    ) {
      result = false;
    } else {
      result = true;
    }

    return result;
  }

  public static getAssetFromAssetListPerCategory(key: string, assetId: number): IAsset | null {
    let asset: IAsset | null = null;

    if (Data.assetListPerCategory[key] !== undefined) {
      const index = Data.assetListPerCategory[key].findIndex(
        (assetItem: IAsset) => assetItem.asset_id === assetId
      );

      if (index !== -1) {
        asset = Data.assetListPerCategory[key][index];
      }
    }

    return asset;
  }
}
