import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import registerServiceWorker from './registerServiceWorker';

window.onerror = (
  msg: string | Event,
  url?: string,
  lineNo?: number,
  columnNo?: number,
  error?: any
) => {
  console.log('window.onerror()');
  const str = msg.toString().toLowerCase();
  const substring = 'script error';
  if (str.indexOf(substring) > -1) {
    alert('Script Error: See Browser Console for Detail');
  } else {
    const message = [
      'Message: ' + msg,
      'URL: ' + url,
      'Line: ' + lineNo,
      'Column: ' + columnNo,
      'Error object: ' + JSON.stringify(error)
    ].join(' - ');

    console.log(message);

    alert(message);
  }

  return false;
};

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);
registerServiceWorker();
