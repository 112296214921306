import * as React from 'react';

import API from '../../api/API';
import IAsset from '../../api/IAsset';
import BackgroundAudio from '../../components/BackgroundAudio/BackgroundAudio';
import LoadingIcon from '../../components/LoadingIcon/LoadingIcon';
import MediaControl from '../../components/MediaControl';
import AppConfig from '../../config/AppConfig';
import FileStorage from '../../data/FileStorage/FileStorage';
import Settings from '../more/Settings';

interface IProps {
  asset: IAsset;
  mediaTagId: string;
  onErrorCallback?: (event: React.SyntheticEvent<HTMLAudioElement, Event>) => void;
}

export default class MediaElement extends React.Component<IProps, { mediaUrl: string | null }> {
  protected static mediaElementId = '';

  constructor(props: IProps) {
    super(props);

    this.state = { mediaUrl: null };
  }

  public render() {
    let mediaElement: JSX.Element | null = null;

    if (this.state.mediaUrl !== null) {
      mediaElement = this.buildMediaElement(this.state.mediaUrl);
    }

    return <div className="MediaElement">{mediaElement}</div>;
  }

  public componentDidMount() {
    BackgroundAudio.setAutoPlay(false);

    LoadingIcon.show();

    const mediaUrl = this.props.asset.media!.media_url!;
    console.log('MediaElement.componentDidMount(). mediaUrl=' + mediaUrl);

    if (FileStorage.isLocalUrl(mediaUrl)) {
      console.log('MediaElement.componentDidMount(). cdvfile');
      FileStorage.toNativeUrl(mediaUrl, (nativeUrl: string) => {
        console.log('MediaElement.componentDidMount(). nativeUrl=' + nativeUrl);
        this.setState({ mediaUrl: nativeUrl });
      });
    } else {
      this.setState({ mediaUrl: this.props.asset.media!.media_url });
    }
  }

  public componentDidUpdate() {
    const mediaElement = document.getElementById(this.props.mediaTagId) as HTMLMediaElement;

    this.playMedia(mediaElement);
  }

  public componentWillUnmount() {
    BackgroundAudio.setAutoPlay(Settings.backgroundAudio);
  }

  protected buildMediaElement(mediaUrl: string): JSX.Element {
    let mediaElement: JSX.Element;

    if (this.props.asset.media?.media_type === API.Asset.AUDIO) {
      mediaElement = (
        /* For fetch to work (downloading assets for offline use) we need
          to add crossOrigin="anonymous". Otherwise image in browser cache will not have
          header "Access-Control-Allow-Origin" */

        <audio
          crossOrigin="anonymous"
          id={this.props.mediaTagId}
          // autoPlay={true}
          controls={false}
          onEnded={MediaControl.ended}
          onTimeUpdate={MediaControl.updateProgressBar}
          onLoadedMetadata={MediaControl.updateProgressBar}
          onLoadedData={LoadingIcon.hide}
          onError={this.props.onErrorCallback}
          onAbort={this.onAbort}
          onStalled={this.onStalled}
          onSuspend={this.onSuspend}
        >
          <source src={mediaUrl} type="audio/mpeg" />
        </audio>
      );
    } else {
      mediaElement = (
        <video
          crossOrigin="anonymous"
          id={this.props.mediaTagId}
          autoPlay={true}
          controls={false}
          playsInline={true}
          onEnded={MediaControl.ended}
          onTimeUpdate={MediaControl.updateProgressBar}
          onLoadedMetadata={MediaControl.updateProgressBar}
          onLoadedData={LoadingIcon.hide}
          onError={this.props.onErrorCallback}
          preload={'metadata'}
          poster={AppConfig.transparentImage}
        >
          <source src={this.props.asset.media!.media_url!} type="video/mp4" />
        </video>
      );
    }

    return mediaElement;
  }

  protected onAbort() {
    console.log('MediaElement.onAbort()');
  }

  // media data is not available
  protected onStalled() {
    console.log('MediaElement.onStalled(). timestamp = ' + Math.floor(Date.now() / 1000));
  }

  protected onSuspend() {
    console.log('MediaElement.onSuspend(). timestamp = ' + Math.floor(Date.now() / 1000));
  }

  protected playMedia(mediaElement: HTMLMediaElement) {
    const promise = mediaElement.play();
    if (promise !== undefined) {
      promise
        .then(() => {
          console.log('ASSET AUDIO autoplay started');
        })
        // we need catch below otherwise there will be error on iPhone browser in dev mode
        // see https://webkit.org/blog/7734/auto-play-policy-changes-for-macos/
        .catch((error: Error) => {
          console.log('Exception while trying to play ASSET AUDIO: ', error.message);
          console.log(error);

          MediaControl.pause(); // show Play button instead of Pause
        });
    }
  }
}
