import * as React from 'react';

import IAsset from '../api/IAsset';
import IPage from '../api/IPage';
import Data from '../data/Data';
import ImageItem from './ImageItem';

import './ImageItemArea.css';

interface IProps {
  assetList: IAsset[];
  page: IPage;
}

export default class ImageItemArea extends React.Component<IProps, any> {
  public render() {
    return (
      <div className="ImageItemArea">
        {Object.entries(this.props.assetList).map((assetItem: [string, IAsset], index) => {
          Data.assetList[assetItem[1].asset_id] = assetItem[1];

          return (
            <ImageItem
              key={index}
              asset={assetItem[1]}
              page={this.props.page}
              assetList={this.props.assetList}
            />
          );
        })}
      </div>
    );
  }
}
