import * as React from 'react';

import Config from '../../config/Config';
import Settings from '../../pages/more/Settings';

import BackgroundAudioFile from '../../assets/audios/Delail_ul_Hayrat_Monday.m4a';

interface IState {
  autoPlay: boolean;
}

export default class BackgroundAudio extends React.Component<any, IState> {
  public static setAutoPlay(value: boolean) {
    BackgroundAudio.that.setState({ autoPlay: value });
  }

  //   public static pauseAutoPlay() {
  //     // if (BackgroundAudio.that.state.autoPlay) {
  //       // at this moment DOM is not ready yet so we need a delay to access it
  //       setTimeout(() => {
  //         const audioElement = document.getElementById('BackgroundAudio');
  //         (audioElement! as HTMLMediaElement).pause();
  //       }, 100);
  //     // }
  //   }

  //   public static cancelPauseAutoPlay() {
  //     if (BackgroundAudio.that.state.autoPlay) {

  //       setTimeout(() => {
  //         const audioElement = document.getElementById('BackgroundAudio');
  // console.log('cancelPauseAutoPlay(). audioElement=');
  // console.log(audioElement);
  //         (audioElement! as HTMLMediaElement).play();
  //       }, 100);
  //     }
  //   }

  public static startOrStopAudio() {
    let method: string;
    let delay: number;

    delay = 300;
    if (BackgroundAudio.that.state.autoPlay) {
      method = 'play';
    } else {
      method = 'pause';
    }

    setTimeout(() => {
      const audioElement = document.getElementById('BackgroundAudio') as HTMLAudioElement;

      if (audioElement !== null) {
        const promise = (audioElement! as any)[method]();
        if (method === 'play' && promise !== undefined) {
          // we need catch below otherwise there will be error on iPhone browser in dev mode
          // see https://webkit.org/blog/7734/auto-play-policy-changes-for-macos/
          promise.catch((error: Error) => {
            console.log('Exception while trying to play BACKGROUND AUDIO: ' + error.message);
          });
        }
      } else {
        console.log('audioElement is null');
      }
    }, delay);
  }

  protected static that: React.Component<any, IState>;

  constructor(props: any) {
    super(props);

    BackgroundAudio.that = this;

    this.state = {
      autoPlay: Settings.backgroundAudio
    };
  }

  public render() {
    return (
      <div className="BackgroundAudio">
        {
          /*this.state.autoPlay === true &&*/ <audio
            id="BackgroundAudio"
            src={Config.appPath + BackgroundAudioFile}
            loop={true}
          />
        }
      </div>
    );
  }

  public componentDidMount() {
    BackgroundAudio.startOrStopAudio();
  }

  public componentDidUpdate() {
    BackgroundAudio.startOrStopAudio();
  }
}
