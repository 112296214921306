import * as React from 'react';

import Image from './Image';

export default class NarratorImage extends React.Component<
  { narrator: { image_thumbnail: string } },
  {}
> {
  public render() {
    return (
      <figure>
        {this.props.narrator !== null && <Image imageUrl={this.props.narrator.image_thumbnail} />}
      </figure>
    );
  }
}
