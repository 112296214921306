import { Action, Location } from 'history';
import { createBrowserHistory } from 'history';
import { createHashHistory } from 'history';

import API from '../api/API';
import Config from '../config/Config';
import Asset from '../pages/asset/Asset';

let MyHistory: any;

if (Config.isCordova) {
  MyHistory = createHistory();
} else {
  MyHistory = createBrowserHistory();
  MyHistory = createHistory();
}
MyHistory.urlList = [];

MyHistory.block((location: Location, action: Action) => {
  MyHistory.urlList.push(location.pathname);

  let result = true;

  if (action === 'POP' && Asset.isMaximized() === true) {
    if (Asset.getAssetType() !== API.Asset.TEXT) {
      Asset.minimize();
      result = false;
    } else {
      Asset.clearAsset();
    }
  }

  return result;
});

MyHistory.previousUrl = () => {
  const result = MyHistory.urlList[MyHistory.urlList.length - 2];

  return result;
};

MyHistory.pageName = () => {
  const result = MyHistory.location.pathname.split('/')[1];

  return result;
};

function createHistory() {
  const myHistory = createHashHistory();

  return myHistory;
}

export default MyHistory;
