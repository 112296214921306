import IAsset from '../api/IAsset';
import FileStorage from '../data/FileStorage/FileStorage';

export default class AssetFileOperations {
  public static getFileList(assetList: IAsset[]): Array<string | null> {
    const fileList: Array<string | null> = [];

    if (assetList !== undefined) {
      assetList.forEach((asset: IAsset, index: number) => {
        fileList.push(asset.media!.media_url);
        fileList.push(asset.image_background);
        fileList.push(asset.image_thumbnail);
      });
    }

    return fileList;
  }

  public static getAssetListSize(
    assetList: IAsset[],
    callback: (totalSize: number) => void,
    errorCallback: (message: string, error: Error) => void
  ) {
    const fileList: Array<string | null> = AssetFileOperations.getFileList(assetList);

    FileStorage.getFileListSize(
      fileList,
      (totalSize: number) => {
        callback(totalSize);
      },
      errorCallback
    );
  }
}
