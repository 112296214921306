import * as React from 'react';

import BackgroundScene from '../../../components/BackgroundScene';
import PageWithTitle from '../../../components/PageWithTitle';
import AppConfig from '../../../config/AppConfig';
import Storage from '../../../data/Storage';
import More from '../More';
import MoreItem from '../MoreItem';

import './Scenes.css';

export default class Scenes extends MoreItem {
  constructor(props: any) {
    More.currentMenuItem = 'Scenes';

    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  public render() {
    return (
      <div className="Scenes">
        <PageWithTitle title="Scenes">
          <ul className="horizontal-scrolling-menu">
            {Object.entries(AppConfig.backgroundSceneList).map((item: any, index) => {
              let className = '';
              if (AppConfig.pageBackgroundScene['Home'] === index) {
                className = 'active';
              }
              return (
                <li key={index} className={className}>
                  <figure data-index={index} onClick={this.onClickHandler}>
                    <BackgroundScene backgroundScene={item[1]} />

                    <figcaption className="bottomTitle">{item[1].title}</figcaption>
                  </figure>
                </li>
              );
            })}
          </ul>

          <div className="backgroundVolume">
            <span className="icon">
              <i className="far fa-images" />
            </span>
            <span>Background Volume</span>
          </div>
        </PageWithTitle>
      </div>
    );
  }

  public componentDidMount() {
    const activeScene = document.querySelector('.Scenes ul li.active');
    activeScene!.scrollIntoView({ inline: 'center' });
  }

  public onClickHandler(event: React.MouseEvent<HTMLInputElement>) {
    if (AppConfig.pageBackgroundScene['Home'] !== Number(event.currentTarget.dataset.index)) {
      AppConfig.pageBackgroundScene['Home'] = Number(event.currentTarget.dataset.index);

      Storage.setItem('homeBackgroundScene', JSON.stringify(AppConfig.pageBackgroundScene['Home']));

      this.setState({});
    }
  }
}
