import * as React from 'react';

interface IProps {
  title: string;
}

export default class PageWithTitle extends React.Component<IProps, any> {
  public render() {
    return (
      <div className="PageWithTitle">
        <header>
          <h2>{this.props.title}</h2>
        </header>

        {this.props.children}
      </div>
    );
  }
}
