export default class Url {
  public static lastChain() {
    const urlPartList = window.location.href.split('/');

    return urlPartList[urlPartList.length - 1];
  }

  public static retina2x(imageUrl: string | null) {
    let result = null;

    if (imageUrl !== null) {
      const fileNameWithoutExtension = imageUrl.substring(0, imageUrl.lastIndexOf('.'));

      const fileExtension = imageUrl.substr(imageUrl.lastIndexOf('.') + 1);

      result = fileNameWithoutExtension + '@2x.' + fileExtension;
    }

    return result;
  }

  public static retina3x(imageUrl: string | null) {
    let result = null;

    if (imageUrl !== null) {
      const fileNameWithoutExtension = imageUrl.substring(0, imageUrl.lastIndexOf('.'));

      const fileExtension = imageUrl.substr(imageUrl.lastIndexOf('.') + 1);

      result = fileNameWithoutExtension + '@3x.' + fileExtension;
    }

    return result;
  }

  public static getFileName(imageUrl: string | null) {
    let result = null;

    if (imageUrl !== null) {
      result = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
    }

    return result;
  }

  public static getFileNamePlusPrefix(imageUrl: string | null, prefix: string) {
    let result = null;

    if (imageUrl !== null) {
      result = prefix + Url.getFileName(imageUrl);
    }

    return result;
  }
}
