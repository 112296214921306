import * as React from 'react';

import BackgroundScene from '../../components/BackgroundScene';
import AppConfig from '../../config/AppConfig';

export default class Home extends React.Component {
  public render() {
    const backgroundScene = AppConfig.backgroundSceneList[AppConfig.pageBackgroundScene['Home']!];

    return (
      <div className="Home">
        <BackgroundScene backgroundScene={backgroundScene} className="background" />
      </div>
    );
  }
}
