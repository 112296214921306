import * as React from 'react';
import { NavLink } from 'react-router-dom';

import API from '../api/API';
import IAsset from '../api/IAsset';
import IPage from '../api/IPage';
import Image from '../components/Image';
import Lang from '../components/Lang';
import Time from '../helpers/Time';
import Asset from '../pages/asset/Asset';
import './ImageItem.css';

interface IProps {
  asset: IAsset;
  page: IPage;
  assetList: IAsset[] /* to play next media */;
}

export default class ImageItem extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props);

    this.showAsset = this.showAsset.bind(this);
  }

  public render() {
    const imageUrl: string | null = this.props.asset.image_thumbnail;

    let classStr = '';
    if (this.props.asset.asset_additionalinfo?.hide_asset_name === '1') {
      classStr = ' hide';
    }
    const Figure = (
      <figure onClick={this.showAsset}>
        {imageUrl !== null && <Image imageUrl={imageUrl} />}
        <figcaption>
          {this.props.asset.asset_type !== API.Asset.PROGRAM &&
            this.props.asset?.media?.media_duration !== null && (
              <header>{Time.roundMinites(this.props.asset!.media!.media_duration)} min</header>
            )}
          <span className={'title' + classStr}>
            <Lang str={this.props.asset.asset_name} />
          </span>

          <footer>
            {this.props.asset.narrator === null ? '' : this.props.asset.narrator.user_fullname}
          </footer>
        </figcaption>
      </figure>
    );

    return (
      <div className="ImageItem">
        <div className="positioning">
          {this.props.asset.asset_type === API.Asset.PROGRAM ? (
            <NavLink to={'Program/' + this.props.asset.asset_id}>{Figure}</NavLink>
          ) : (
            Figure
          )}
        </div>
      </div>
    );
  }

  protected showAsset() {
    if (this.props.asset.asset_type !== API.Asset.PROGRAM) {
      Asset.setAssetId(this.props.asset.asset_id, this.props.page, this.props.assetList);
    }
  }
}
