import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import './PageWithTopNavBar.css';

import API from '../api/API';
import IAsset from '../api/IAsset';
import IPage from '../api/IPage';
import BackgroundScene from '../components/BackgroundScene';
import HorizontalScrollingMenu from '../components/HorizontalScrollingMenu';
import ImageItemArea from '../components/ImageItemArea';
import MyHistory from '../components/MyHistory';
import AppConfig from '../config/AppConfig';
import Data from '../data/Data';
import Storage from '../data/Storage';
import Url from '../helpers/Url';

interface IProps {
  pageName: string;
}

interface IState {
  secondaryRoute: string;
  assetList: IAsset[];
  error?: string | null;
}

export default class PageWithTopNavBar extends React.Component<
  RouteComponentProps<IProps>,
  IState
> {
  public static topNavBarIndex: { [key: string]: string } = {};

  public static updateAssetList() {
    PageWithTopNavBar.self.fetchAssets();
  }

  protected static self: PageWithTopNavBar;

  public state: IState;

  constructor(props: RouteComponentProps<IProps>) {
    super(props);

    PageWithTopNavBar.self = this;

    if (!PageWithTopNavBar.topNavBarIndex.hasOwnProperty(this.getPageName())) {
      if (window.location.hash.split('/').length <= 2) {
        // url has only one part (/Sleep and not /Sleep/All)
        PageWithTopNavBar.topNavBarIndex[this.getPageName()] = Data.getCategoryListForPage(
          this.getPageName()
        )[0];
      } else {
        PageWithTopNavBar.topNavBarIndex[this.getPageName()] = Url.lastChain();
      }
    }

    this.state = {
      secondaryRoute: PageWithTopNavBar.topNavBarIndex[this.getPageName()],
      assetList: [],
      error: null
    };

    this.setSubMenu(props.match.params.pageName, true);
  }

  public componentDidMount() {
    this.fetchAssets();
  }

  public shouldComponentUpdate(
    nextProps: Readonly<RouteComponentProps<IProps>>,
    nextState: Readonly<any>,
    nextContext: any
  ): boolean {
    const previousRoute = this.getPageNameAndCategory();

    const newPageName = nextProps.match.params.pageName;
    this.setSubMenu(newPageName, false);

    const currentRoute = this.getPageNameAndCategory();

    // fetch assets only if switching to another Category (shouldComponentUpdate is also called
    // when Ajax fails, in this case don't fetch assets second time)
    if (previousRoute !== currentRoute || true) {
      this.fetchAssets();
    }

    // we have to return true to re-render to show newly selected submenu even
    // if AJAX didn't return results yet.
    return true;
  }

  public render() {
    let pageStyle = undefined;

    const backgroundSceneIndex = AppConfig.pageBackgroundScene[this.getPageName()];
    const page: IPage = Data.getPage(this.getPageName())!;
    let headerStyle: React.CSSProperties = {};

    if (page !== undefined) {
      const backgroundTopColor = page.page_additionalinfo?.page_backgroundtopcolor;
      const backgroundBottomColor = page.page_additionalinfo?.page_backgroundbottomcolor;

      pageStyle = {
        backgroundImage:
          'linear-gradient(' + backgroundTopColor + ', ' + backgroundBottomColor + ')'
      };

      headerStyle = {
        backgroundSize: 'cover',
        backgroundColor: page.page_additionalinfo?.page_backgroundheadercolor
      };
      if (page.page_top_header_image?.image_url !== undefined) {
        headerStyle.backgroundImage = 'url(' + page.page_top_header_image?.image_url + ')';
      }
    }

    return (
      page !== undefined && (
        <div className={'PageWithTopNavBar'} style={pageStyle}>
          <header style={headerStyle}>
            <h2 style={{ color: page.page_additionalinfo?.page_titlecolor }}>
              {this.getPageName()}
            </h2>
            <HorizontalScrollingMenu
              menuItemList={Data.getCategoryListForPage(this.getPageName())}
              menuItemActiveStyle={page.page_additionalinfo?.page_topmenuitemactivecss}
              menuItemNonActiveStyle={page.page_additionalinfo?.page_topmenuitemnonactivecss}
              urlPrefix={this.getPageName()}
            />
          </header>

          <section>
            {backgroundSceneIndex && (
              <BackgroundScene
                backgroundScene={AppConfig.backgroundSceneList[backgroundSceneIndex]}
                className="background"
              />
            )}

            {PageWithTopNavBar.topNavBarIndex[this.getPageName()] === this.state.secondaryRoute && (
              <ImageItemArea
                assetList={this.state.assetList}
                page={Data.getPage(this.getPageName())!}
              />
            )}

            {this.state.error !== null &&
              PageWithTopNavBar.topNavBarIndex[this.getPageName()] !== 'My' && (
                <div className="error fixed-centered">{this.state.error}</div>
              )}
          </section>
        </div>
      )
    );
  }

  protected setTopNavBarIndex(pageName: string) {
    if (!PageWithTopNavBar.topNavBarIndex.hasOwnProperty(pageName)) {
      if (window.location.hash.split('/').length <= 2) {
        // url has only one part (/Sleep and not /Sleep/All)
        PageWithTopNavBar.topNavBarIndex[pageName] = Data.getCategoryListForPage(pageName)[0];
      } else {
        PageWithTopNavBar.topNavBarIndex[pageName] = Url.lastChain();
      }
    }
  }

  protected setSubMenu(pageName: string, fromConstructor: boolean) {
    if (window.location.hash.split('/').length <= 2) {
      // url has only one part (/Sleep and not /Sleep/All)
      this.setTopNavBarIndex(pageName);
      MyHistory.replace(pageName + '/' + PageWithTopNavBar.topNavBarIndex[pageName]);
    }

    let state: IState;
    state = {
      secondaryRoute: Url.lastChain(),
      assetList: this.state.assetList
    };

    if (state.secondaryRoute !== PageWithTopNavBar.topNavBarIndex[pageName]) {
      PageWithTopNavBar.topNavBarIndex[pageName] = state.secondaryRoute;

      if (fromConstructor) {
        // can't use setState since component is not mounted yet
        // eslint-disable-next-line
        this.state = state;
      } else {
        // this.setState(state);
      }
    }
  }

  protected getPageName(): string {
    const result = this.props.match.params.pageName;

    return result;
  }

  protected getPageNameAndCategory(): string {
    const pageName = this.props.match.params.pageName;

    const result = pageName + '/' + PageWithTopNavBar.topNavBarIndex[pageName];

    return result;
  }

  protected fetchAssets() {
    const key = this.getPageName() + '/' + PageWithTopNavBar.topNavBarIndex[this.getPageName()];

    const categoryName = Data.categoryPerRoute[key];

    if (Data.assetListPerCategory[key] === undefined) {
      if (categoryName === Data.OFFLINE_MENU_ITEM) {
        Data.assetListPerCategory[key] = [];
        this.setState({
          assetList: [],
          secondaryRoute: PageWithTopNavBar.topNavBarIndex[this.getPageName()],
          error: null
        });
      } else if (categoryName !== undefined) {
        API.getAssets(this.getPageName(), categoryName)
          .then(response => {
            Data.setAssetListPerCategory(key, response.data);

            this.setState({
              assetList: Data.assetListPerCategory[key],
              secondaryRoute: PageWithTopNavBar.topNavBarIndex[this.getPageName()],
              error: null
            });
          })
          .catch(error => {
            console.log(
              'Error when calling API.getAssets("' +
                this.getPageName() +
                '", "' +
                categoryName +
                '"):'
            );
            console.log(error);

            const assetList = Storage.getItem(key);
            if (assetList !== null) {
              Data.assetListPerCategory[key] = JSON.parse(assetList);

              this.setState({
                assetList: JSON.parse(assetList),
                secondaryRoute: PageWithTopNavBar.topNavBarIndex[this.getPageName()],
                error: null
              });
            } else {
              if (this.state.error !== API.FETCH_ERROR) {
                this.setState({ error: API.FETCH_ERROR });
              }
            }
          });
      }
    } else {
      if (this.state.assetList !== Data.assetListPerCategory[key]) {
        this.setState({
          assetList: Data.assetListPerCategory[key],
          secondaryRoute: PageWithTopNavBar.topNavBarIndex[this.getPageName()],
          error: null
        });
      }
    }
  }
}
