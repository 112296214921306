import * as React from 'react';

import { NavLink } from 'react-router-dom';

import './More.css';

export default class More extends React.Component {
  public static currentMenuItem: any;

  protected menuList = {
    Scenes: 'far fa-images',
    Settings: 'fal fa-cog',
    Downloads: 'fal fa-save',
    System: 'fas fa-info',
    About: 'fas fa-question'
  };

  public render() {
    More.currentMenuItem = undefined;

    return (
      <div className="More">
        <ul>
          {Object.entries(this.menuList).map((item, index) => {
            return (
              <li key={index}>
                <NavLink key={index} to={process.env.PUBLIC_URL + '/More/' + item[0]}>
                  <div className={'iconContainer ' + item[0]}>
                    <i className={item[1]} />
                  </div>
                  {item[0]}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
