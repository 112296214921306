export default class Html {
  public static strip(str: string) {
    const div = document.createElement('div');
    div.innerHTML = str;

    return div.innerText;
  }

  public static replaceSpacesWithDashes(str: string) {
    return str.trim().replace(/ /g, '-');
  }

  public static cssToObj(css: string): React.CSSProperties {
    let obj: any = {};

    if (css !== null && css !== undefined) {
      let s = css
        .toLowerCase()
        .replace(/;\s?$/g, '')
        .split(/:|;/g);

      for (var i = 0; i < s.length; i += 2) {
        let property = s[i].replace(/\s/g, '').replace(/-(.)/g, (m, g) => g.toUpperCase());

        obj[property] = s[i + 1].replace(/^\s+|\s+$/g, '');
      }
    }

    return obj;
  }
}
