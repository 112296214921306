import * as React from 'react';

import Time from '../helpers/Time';
import Asset from '../pages/asset/Asset';
import LoadingIcon from './LoadingIcon/LoadingIcon';

import './MediaControl.css';

interface IProps {
  mediaTagId: string;
  alreadyPlaying: boolean;
  minimized: boolean;
}

interface IState {
  playClass: string;
  pauseClass: string;
}

export default class MediaControl extends React.Component<IProps, IState> {
  public static updateProgressBar() {
    const mediaElement: HTMLMediaElement = document.getElementById(
      MediaControl.mediaElementId
    ) as HTMLMediaElement;
    document.getElementById('CurrentTime')!.textContent = Time.secondsToStr(
      mediaElement.currentTime
    );

    const progressElement: HTMLProgressElement = document.getElementById(
      'MediaProgress'
    ) as HTMLProgressElement;
    progressElement!.value = mediaElement.currentTime;

    const duration: number = mediaElement.duration;
    if (!Number.isNaN(duration) && isFinite(duration)) {
      // only if audio loaded
      document.getElementById('MaxTime')!.textContent = Time.secondsToStr(duration);
      progressElement!.max = duration;
    } else {
      // on iPhone audio tag doesn't through onError if offline
      Asset.errorHandling();
    }

    const progressBarCircle = document.getElementById('progressBarCircle');
    progressBarCircle!.style.top =
      String(
        progressElement!.offsetTop +
          progressElement!.clientHeight / 2 +
          progressBarCircle!.clientHeight / 2
      ) + 'px';
    progressBarCircle!.style.left =
      String(
        progressElement!.offsetLeft -
          progressBarCircle!.clientWidth / 2 +
          (progressElement!.offsetWidth * progressElement!.value) / progressElement.max
      ) + 'px';
    progressBarCircle!.style.visibility = 'visible';
  }

  public static ended() {
    window.removeEventListener('resize', MediaControl.updateProgressBar);

    MediaControl.pause();
    LoadingIcon.hide(); // on iPhone it takes very long to load sometimes
    Asset.playNextAsset();
  }

  public static stop() {
    window.removeEventListener('resize', MediaControl.updateProgressBar);

    MediaControl.pause();
    LoadingIcon.hide(); // on iPhone it takes very long to load sometimes
    Asset.clearAsset();
  }

  public static pause() {
    const mediaElement = document.getElementById(
      MediaControl.self.props.mediaTagId
    ) as HTMLMediaElement;

    if (mediaElement !== null) {
      mediaElement['pause']();
    }

    MediaControl.self.setState({
      playClass: '',
      pauseClass: 'hide'
    });
  }

  protected static self: MediaControl;

  protected static mediaElementId: string;

  constructor(props: IProps) {
    super(props);

    MediaControl.self = this;

    MediaControl.mediaElementId = this.props.mediaTagId;

    this.state = {
      playClass: this.props.alreadyPlaying ? 'hide' : '',
      pauseClass: this.props.alreadyPlaying ? '' : 'hide'
    };

    this.play = this.play.bind(this);
    this.forward = this.forward.bind(this);
    this.backward = this.backward.bind(this);
    this.seek = this.seek.bind(this);

    window.addEventListener('resize', MediaControl.updateProgressBar);
  }

  public play() {
    const media = document.getElementById(this.props.mediaTagId) as HTMLMediaElement;
    media!['play']();

    this.setState({
      playClass: 'hide',
      pauseClass: ''
    });
  }

  public forward() {
    this.getMediaElement().currentTime += 15;
  }

  public backward() {
    this.getMediaElement().currentTime -= 15;
  }

  public seek(event: React.MouseEvent<HTMLProgressElement>) {
    const percent =
      event.nativeEvent.offsetX / (event.nativeEvent.target as HTMLProgressElement)!.offsetWidth;

    this.getMediaElement().currentTime = percent * this.getMediaElement().duration;
  }

  public render() {
    return (
      <div id="MediaControl" className={'MediaControl' + (this.props.minimized ? ' minimize' : '')}>
        <div className="MediaControlButtons">
          <i className="fal fa-undo" onClick={this.backward}>
            <span>15</span>
          </i>

          <i className={'fas fa-play ' + this.state.playClass} onClick={this.play} />

          <i className={'far fa-pause ' + this.state.pauseClass} onClick={MediaControl.pause} />

          <i className="fal fa-redo" onClick={this.forward}>
            <span>15</span>
          </i>

          <i className="fas fa-stop" onClick={MediaControl.stop} />
        </div>

        <div className="MediaControlProgressBar">
          <progress id="MediaProgress" value="0" onClick={this.seek} />
          <footer>
            <span id="CurrentTime" />
            <span id="MaxTime" />
          </footer>
          <div id="progressBarCircle" />
        </div>
      </div>
    );
  }

  public componentDidUpdate(prevProps: any, prevState: any) {
    // if asset page was open as first page of App then audio will not play
    // until user's interactions so show Play button instead of Pause.
    if (
      this.getMediaElement() !== null &&
      this.getMediaElement().paused &&
      this.state.pauseClass === ''
    ) {
      //   this.pause();
    }
  }

  protected getMediaElement(): HTMLMediaElement {
    const mediaElement = document.getElementById(this.props.mediaTagId);

    return mediaElement as HTMLMediaElement;
  }
}
