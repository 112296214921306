// declare let cordova: any;

export default class Config {
  static get version(): string {
    return '2020-06-21 20:00';
  }

  static get isCordova(): boolean {
    const result = window.location.protocol === 'file:';

    return result;
  }

  static get isIPhone(): boolean {
    const result = navigator.userAgent.includes('iPhone');

    return result;
  }

  public static get appPath(): string {
    let result;

    if (this.isCordova) {
      if ((window as any).cordova.platformId === 'ios') {
        result = '.';
        // console.log('Config::appPath(): Cordova ios');
      } else {
        result = 'file:///android_asset/www';
        // console.log('Config::appPath(): Cordova Android');
      }
    } else {
      result = process.env.PUBLIC_URL as string;
      // console.log('Config::appPath(): Web');
    }

    return result;
  }

  public static adjustPath(path: string | null): string {
    let result;

    if (path === null) {
      result = '';
    } else if (path.includes(':')) {
      // absolute url
      result = path;
    } else {
      result = Config.appPath + path;
    }

    return result;
  }

  public static getImagesUrl(): string | undefined {
    let result;

    result = this.appPath + '/images/';

    return result;
  }

  public static getAudioUrl(): string | undefined {
    let result;

    result = this.appPath + '/audio/';

    return result;
  }

  public static getVideosUrl(): string | undefined {
    let result;

    result = this.appPath + '/videos/';

    return result;
  }
}
