import * as React from 'react';

import './Program.css';

import API from '../../api/API';
import IAsset from '../../api/IAsset';
import Lang from '../../components/Lang';
import MyHistory from '../../components/MyHistory';
import NarratorImage from '../../components/NarratorImage';
import Data from '../../data/Data';
import Asset from '../asset/Asset';
import Time from '../../helpers/Time';

export default class Program extends React.Component<any, { asset: IAsset | null }> {
  constructor(props: any) {
    super(props);

    let asset: IAsset | null = null;

    if (Data.assetList[this.props.match.params.id] !== undefined) {
      asset = Data.assetList[this.props.match.params.id];
    }

    this.state = { asset };

    this.showAsset = this.showAsset.bind(this);
    this.shuffle = this.shuffle.bind(this);
  }

  public render() {
    return (
      this.state.asset !== null && (
        <div className="Program">
          <header>
            <div>
              <span className="float-left">
                <i className="fal fa-chevron-circle-left" onClick={MyHistory.goBack} />
              </span>

              <span className="float-right">
                <i className="fal fa-random" onClick={this.shuffle}></i>
              </span>

              <h2 className="title">
                <Lang str={this.state.asset.asset_name} />
              </h2>
            </div>

            {this.state.asset.narrator !== null && (
              <div>
                <NarratorImage narrator={this.state.asset.narrator} />
                <div>{this.state.asset.narrator.user_fullname}</div>
                <div>{this.state.asset.narrator.user_title}</div>
              </div>
            )}
          </header>

          <section className="description-and-asset-list">
            <div className="description">
              {this.state.asset.asset_description}
              <div className="horizontal-line" />
            </div>

            <ol>
              {Object.entries(this.state.asset.program!.media).map(
                (assetItem: [string, IAsset], index) => {
                  const isLiked = Data.isInAssetListPerCategory(
                    MyHistory.pageName() + '/My',
                    assetItem[1]!
                  );

                  return (
                    <li key={index}>
                      <span className="orderNumber vertically-centered">
                        <span className="centered">{index + 1}</span>
                      </span>

                      <div className="title-container">
                        <span
                          className="title centered"
                          data-asset-id={assetItem[1].asset_id}
                          onClick={this.showAsset}
                        >
                          <Lang str={assetItem[1].asset_name} />

                          {assetItem[1].media!.media_duration &&
                            ' (' + Time.roundMinites(assetItem[1].media!.media_duration!) + 'min)'}
                        </span>
                      </div>

                      <span className="likeIcon vertically-centered">
                        <i
                          className={isLiked ? Asset.SELECTED_LIKE : Asset.NON_SELECTED_LIKE}
                          onClick={this.like}
                          data-asset-id={assetItem[1].asset_id}
                        />
                      </span>
                    </li>
                  );
                }
              )}
            </ol>
          </section>
        </div>
      )
    );
  }

  public componentDidMount() {
    if (Data.assetList[this.props.match.params.id] === undefined) {
      API.getAsset(this.props.match.params.id).then((response: IAsset) => {
        response.program!.media.forEach((assetItem: IAsset) => {
          Data.assetList[assetItem.asset_id] = assetItem;
        });

        this.setState({
          asset: response
        });
      });
    } else {
      Data.assetList[this.props.match.params.id].program!.media.forEach((assetItem: IAsset) => {
        Data.assetList[assetItem.asset_id] = assetItem;
      });
    }
  }

  protected showAsset(event: React.MouseEvent<HTMLSpanElement>) {
    const htmlElement = event.nativeEvent.target as HTMLElement;
    const assetId: number = parseInt(htmlElement.dataset.assetId!, 10);

    Asset.setAssetId(
      assetId,
      Data.getPage(this.props.match.params.pageName)!,
      this.state.asset!.program!.media
    );
  }

  protected like(event: React.MouseEvent<HTMLElement>) {
    const iElement = event.nativeEvent.target as HTMLElement;
    const assetId: number = parseInt(iElement.dataset.assetId!, 10);
    const asset = Data.assetList[assetId];

    if (iElement!.className.includes(Asset.SELECTED_LIKE)) {
      iElement!.className = Asset.NON_SELECTED_LIKE;

      Data.removeFromAssetListPerCategory(MyHistory.pageName() + '/My', asset);
    } else {
      iElement!.className = Asset.SELECTED_LIKE;

      Data.addToAssetListPerCategory(MyHistory.pageName() + '/My', asset);
    }
  }

  protected shuffle(event: React.MouseEvent<HTMLSpanElement>) {
    const mediaList: IAsset[] = [];
    const arr: number[] = [];
    this.state.asset!.program!.media.forEach((value: any, index: number) => {
      arr.push(index);
    });

    while (arr.length > 0) {
      let randomIndex = Math.floor(Math.random() * arr.length);
      mediaList.push(this.state.asset!.program!.media[arr[randomIndex]]);
      arr.splice(randomIndex, 1);
    }

    Asset.setAssetId(
      mediaList[0].asset_id,
      Data.getPage(this.props.match.params.pageName)!,
      mediaList
    );
  }
}
