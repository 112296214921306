import * as React from 'react';

import './SliderSwitch.css';

interface IProps {
  isChecked: boolean;
  propertyName: string;
  callback: (isChecked: boolean, propertyName: string) => void;
}

export default class SliderSwitch extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public render() {
    return (
      <div className="SliderSwitch">
        <label className="switch">
          <input
            type="checkbox"
            defaultChecked={this.props.isChecked}
            onChange={this.onChangeHandler}
          />
          <span className="slider round" />
        </label>
      </div>
    );
  }

  protected onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    this.props.callback(event.target.checked, this.props.propertyName);
  }
}
