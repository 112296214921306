import Config from '../../config/Config';
import FetchError from '../../data/FileStorage/FetchError';
import FileOperationError from '../../data/FileStorage/FileOperationError';
import FileSystemError from '../../data/FileStorage/FileSystemError';

const Window: any = window;
Window.requestFileSystem = Window.requestFileSystem || Window.webkitRequestFileSystem;

const Navigator: any = navigator;

export default class FileStorage {
  public static MAX_STORAGE_SIZE = 30 * 1024 * 1024;

  public static get prefix() {
    let result: string;

    if (Config.isCordova) {
      result = 'cdvfile://localhost/persistent/';
    } else {
      result =
        'filesystem:' + window.location.protocol + '//' + window.location.hostname + '/persistent/';
    }

    return result;
  }

  public static isLocalUrl(url: string): boolean {
    let result = false;
    if (url.includes('cdvfile') || url.includes('filesystem')) {
      result = true;
    }

    return result;
  }

  public static getStorageSize(
    successCallback: (usedBytes: any, grantedBytes: any) => void,
    errorCallback?: (message: string, error: Error) => void
  ) {
    console.log('FileStorage.getStorageSize()');

    if (Navigator.webkitPersistentStorage) {
      Navigator.webkitPersistentStorage.queryUsageAndQuota(
        (usedBytes: any, grantedBytes: any) => {
          successCallback(usedBytes, grantedBytes);
        },
        (error: any) => {
          if (errorCallback) {
            errorCallback('FileStorage.getStorageSize():', new FileSystemError(error.message));
          }
        }
      );
    }
  }

  public static downloadManyFiles(
    fileList: { [key: string]: string },
    successCallback: () => void,
    errorCallback: (message: string, error: Error) => void,
    index: number = 0
  ) {
    const remoteFileName = Object.keys(fileList)[index];
    const localFileName = fileList[remoteFileName];

    if (index === Object.keys(fileList).length) {
      successCallback();
    } else {
      const nextCallback = () => {
        FileStorage.downloadManyFiles(fileList, successCallback, errorCallback, index + 1);
      };

      FileStorage.downloadOneFile(remoteFileName, localFileName, nextCallback, errorCallback);
    }
  }

  public static downloadOneFile(
    remoteFileUrl: string | null,
    localFilePath: string | null,
    successCallback: (fullPath: string | null) => void,
    errorCallback?: (message: string, error: Error) => void
  ) {
    console.log('FileStorage.download(). remoteFileUrl=', remoteFileUrl);
    console.log('FileStorage.download(). localFilePath=', localFilePath);
    if (remoteFileUrl === null) {
      successCallback(null); // go to next section
    } else {
      fetch(remoteFileUrl)
        .then(response => {
          console.log(response);

          response
            .blob()
            .then(myBlob => {
              FileStorage.saveFile(
                localFilePath!,
                myBlob,
                (fullPath: string) => {
                  console.log('FileStorage.download(). fullPath=' + fullPath);
                  successCallback(fullPath);
                },
                (message: string, error: Error) => {
                  if (errorCallback) {
                    errorCallback(message, error);
                  }
                }
              );
            })
            .catch(error => {
              if (errorCallback) {
                errorCallback('FileStorage.download():', new FileSystemError(error.message));
              }
            });
        })
        .catch(error => {
          if (errorCallback) {
            errorCallback('FileStorage.download(): Fetch error:', new FetchError(error.message));
          }
        });
    }
  }

  public static getFileListSize(
    fileList: Array<string | null>,
    successCallback: (totalSize: number) => void,
    errorCallback?: (message: string, error: Error) => void
  ) {
    // we need object to refer to pass it by reference to inner scope
    const obj = { totalSize: 0, processedFileCounter: 0 };

    if (!Window.requestFileSystem) {
      if (errorCallback) {
        errorCallback(
          'FileStorage.getFileListSize():',
          new FileSystemError('window.requestFileSystem is not defined')
        );
      }
    } else {
      Window.requestFileSystem(
        Window.PERSISTENT,
        0,
        (fs: any) => {
          if (fileList.length === 0) {
            successCallback(obj.totalSize);
          }

          fileList.forEach((filePath, index) => {
            if (filePath !== null) {
              filePath = filePath!.replace(FileStorage.prefix, '');

              fs.root.getFile(
                filePath,
                { create: false },
                (fileEntry: any) => {
                  fileEntry.getMetadata(
                    (metadata: any) => {
                      obj.processedFileCounter++;
                      obj.totalSize += metadata.size;
                      console.log('FileStorage.getFileListSize(). counted file: ' + filePath);

                      if (obj.processedFileCounter === fileList.length) {
                        console.log(
                          'fileList.length=' +
                            fileList.length +
                            ' obj.processedFileCounter=' +
                            obj.processedFileCounter
                        );
                        console.log(
                          'end1 of FileStorage.getFileListSize(). Total size = ' +
                            obj.totalSize.toLocaleString()
                        );
                        successCallback(obj.totalSize);
                      }
                    },
                    (fileError: any) => {
                      console.log(
                        'FileStorage.getFileListSize(). onErrorGetMetadata. file: ',
                        filePath
                      );
                      FileStorage.errorHandler(fileError);
                    }
                  );
                },
                (fileError: any) => {
                  console.log('FileStorage.getFileListSize(). onErrorGetFile. file: ', filePath);
                  FileStorage.errorHandler(fileError);
                }
              );
            } else {
              // filePath == null
              obj.processedFileCounter++;
              if (obj.processedFileCounter === fileList.length) {
                console.log(
                  'end2 of FileStorage.getFileListSize(). Total size = ' +
                    obj.totalSize.toLocaleString()
                );
                successCallback(obj.totalSize);
              }
            } // if (filePath !== null) {
          });
        },
        (fileError: any) => {
          console.log('FileStorage.getFileListSize(). onError requestFileSystem:');
          FileStorage.errorHandler(fileError);
        }
      );
    }
  }

  public static deleteFile(filePath: string, successCallback?: () => void) {
    filePath = filePath.replace(FileStorage.prefix, '');
    Window.requestFileSystem(
      Window.PERSISTENT,
      0,
      (fs: any) => {
        fs.root.getFile(
          filePath,
          { create: false },
          (fileEntry: any) => {
            fileEntry.remove(
              () => {
                console.log('File removed:', filePath);

                if (successCallback) {
                  successCallback();
                }
              },
              (fileError: any) => {
                console.log('FileStorage.deleteFile(). onError fileEntry.remove');
                FileStorage.errorHandler(fileError);
              }
            );
          },
          (fileError: any) => {
            console.log('FileStorage.deleteFile(). onError getFile. file: ', filePath);
            FileStorage.errorHandler(fileError);
          }
        );
      },
      (fileError: any) => {
        console.log('FileStorage.deleteFile(). onError requestFileSystem');
        FileStorage.errorHandler(fileError);
      }
    );
  }

  // from https://www.html5rocks.com/en/tutorials/file/filesystem/#toc-dir
  public static listDirectory(
    successCallback?: (fileList: string[]) => void,
    errorCallback?: (message: string, error: Error) => void
  ) {
    console.log('FileStorage.listDirectory()');

    if (Navigator.webkitPersistentStorage) {
      Navigator.webkitPersistentStorage.queryUsageAndQuota(
        (usedBytes: any, grantedBytes: any) => {
          console.log(
            'we are using ',
            usedBytes.toLocaleString(),
            ' of ',
            grantedBytes.toLocaleString(),
            'bytes'
          );
        },
        (e: any) => {
          console.log('Error', e);
        }
      );
    }

    if (!Window.requestFileSystem) {
      if (errorCallback) {
        errorCallback(
          'FileStorage.listDirectory():',
          new FileSystemError('window.requestFileSystem is not defined')
        );
      }
    } else {
      Window.requestFileSystem(
        Window.PERSISTENT,
        0,
        (fs: any) => {
          const dirReader = fs.root.createReader();
          let entries: any = [];

          // Call the reader.readEntries() until no more results are returned.
          const readEntries = () => {
            dirReader.readEntries(
              (results: any) => {
                if (!results.length) {
                  FileStorage.listDirectoryEntries(entries.sort(), fileList => {
                    if (successCallback) {
                      successCallback(fileList);
                    }
                  });
                } else {
                  entries = entries.concat(Array.prototype.slice.call(results || [], 0));
                  readEntries();
                }
              },
              () => console.log('onError readEntries')
            );
          };

          readEntries(); // Start reading dirs.
        },
        (error: any) => {
          console.log(
            'FileStorage.listDirectory(). onError requestFileSystem: ' + error.message,
            error
          );

          if (errorCallback) {
            errorCallback(
              'FileStorage.listDirectory():',
              new FileSystemError('window.requestFileSystem() error: ' + error.message)
            );
          }
        }
      );
    }
  } // listDirectory()

  public static calculateAllDownloadedFileSize(
    successCallback: (totalSize: number) => void,
    errorCallback: (message: string, error: Error) => void
  ): void {
    console.log('FileStorage.calculateAllDownloadedFileSize()');

    FileStorage.listDirectory((fileList: string[]) => {
      console.log('fileList=', fileList);
      FileStorage.getFileListSize(
        fileList,
        (totalSize: number) => {
          console.log(
            'FileStorage.calculateAllDownloadedFileSize(). totalSize=',
            totalSize.toLocaleString()
          );
          successCallback(totalSize);
        },
        errorCallback
      );
    }, errorCallback);
  }

  // iPhone gives error for cdvfile media url but works with "native" url
  public static toNativeUrl(
    url: string,
    successCallback: (nativeUrl: string) => void,
    errorCallback?: (message: string, error: Error) => void
  ) {
    Window.resolveLocalFileSystemURL(
      url,
      (fileEntry: any) => {
        console.log('FileStorage.toNativeUrl(). Window.resolveLocalFileSystemURL()');
        console.log(fileEntry.toURL());

        successCallback(fileEntry.toURL());
      },
      (error: any) => {
        console.log(
          'FileStorage.toNativeUrl(). error resolveLocalFileSystemURL: ',
          error.message,
          error
        );
        if (errorCallback) {
          errorCallback('FileStorage.toNativeUrl(). error resolveLocalFileSystemURL', error);
        }
      }
    );
  }

  protected static requestQuota(
    requestedBytes: number,
    successCallback: (grantedBytes: number) => void
  ) {
    if (Config.isCordova) {
      successCallback(0);
    } else if (!Navigator.webkitPersistentStorage) {
      throw new FileSystemError(
        'FileStorage.requestQuota(): navigator.webkitPersistentStorage is not defined'
      );
    } else if (!Window.requestFileSystem) {
      throw new FileSystemError(
        'FileStorage.requestQuota(): window.requestFileSystem is not defined'
      );
    } else {
      Navigator.webkitPersistentStorage.requestQuota(requestedBytes, (grantedBytes: any) => {
        console.log('requestedBytes=', requestedBytes, ' grantedBytes=', grantedBytes);

        successCallback(grantedBytes);
      });
    } // if
  }

  protected static saveFile(
    localFilePath: string,
    blob: Blob,
    successCallback: (fullPath: string) => void,
    errorCallback?: (message: string, error: Error) => void
  ) {
    let requestedBytes = blob.size;
    requestedBytes = FileStorage.MAX_STORAGE_SIZE;

    FileStorage.requestQuota(requestedBytes, grantedBytes => {
      Window.requestFileSystem(
        Window.PERSISTENT,
        grantedBytes,
        (fs: any) => {
          console.log('file system open: ' + fs.name);
          fs.root.getFile(
            localFilePath,
            { create: true, exclusive: false },
            (fileEntry: any) => {
              FileStorage.writeFile(fileEntry, blob, (fullPath: string) => {
                successCallback(fullPath);
              });
            },
            (error: any) => {
              console.log('onErrorCreateFile:');
              FileStorage.errorHandler(error);

              if (errorCallback) {
                errorCallback(
                  'FileStorage.saveFile(): onErrorCreateFile',
                  new FileOperationError('FileStorage.saveFile(): onErrorCreateFile')
                );
              }
            }
          );
        },
        (error: any) => {
          console.log('FileStorage.saveFile(). onError requestFileSystem');
          FileStorage.errorHandler(error);

          if (errorCallback) {
            errorCallback(
              'FileStorage.saveFile():',
              new FileSystemError('window.requestFileSystem() error: ' + error.message)
            );
          }
        }
      );
    });
  } // saveFile()

  protected static writeFile(
    fileEntry: any,
    dataObj: any,
    successCallback: (fullPath: string) => void
  ) {
    // Create a FileWriter object for our FileEntry (log.txt).
    fileEntry.createWriter((fileWriter: any) => {
      fileWriter.onwriteend = () => {
        console.log('The following file has been successfully written: ' + fileEntry.fullPath);
        successCallback(fileEntry.fullPath);
      };

      fileWriter.onerror = (fileError: any) => {
        console.log('FileStorage.writeFile(). Failed file write: ' + fileError.toString());
        FileStorage.errorHandler(fileError);
      };

      fileWriter.write(dataObj);
    });
  } // writeFile()

  protected static listDirectoryEntries(entries: any, callback: (fileList: string[]) => void) {
    console.log('FileStorage.listDirectoryEntries()');
    const fileList: string[] = [];
    console.log('entries=', entries);
    if (entries.length === 0) {
      callback([]);
    }

    entries.forEach((entry: any, i: number) => {
      const entryType = entry.isDirectory ? 'dir' : 'file';
      if (entry.isDirectory) {
        console.log('FileStorage.listDirectoryEntries(). ' + entryType + ': ' + entry.fullPath);

        if (entries.length - 1 === i) {
          callback(fileList);
        }
      } else {
        entry.file((file: any) => {
          console.log(
            'FileStorage.listDirectoryEntries(). ' +
              entryType +
              ': ' +
              entry.fullPath +
              ', size=' +
              file.size.toLocaleString()
          );

          fileList.push(entry.fullPath);

          if (entries.length - 1 === i) {
            callback(fileList);
          }
        });
      }
    });
  } // listDirectoryEntries

  protected static errorHandler(e: any) {
    console.log('Error class: ', e.constructor.name);
    console.log('Error code: ', e.code);
    console.log('Error name: ', e.name);
    console.log('Error message: ', e.message);
  }
}
