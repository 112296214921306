import * as React from 'react';
import { NavLink } from 'react-router-dom';

import IPage from '../api/IPage';

import './NavBar.css';

export default class NavBar extends React.Component<{ navItemList: IPage[] }> {
  public static homeMenuItem: IPage[] = [
    {
      page_name: 'Home',
      page_additionalinfo: {
        page_bottommenuitemactiveelement:
          '<span style="border: 2px solid #75D1C2; border-radius: 50%; background-color: #75D1C2;">' +
          '<i class="fas fa-home-lg-alt" style="font-size: large; align-self: center;"></i></span>',
        page_bottommenuitemnonactiveelement:
          '<span style="border: 2px solid #75D1C2; border-radius: 50%;">' +
          '<i class="fal fa-home-lg-alt" style="font-size: large; align-self: center;"></i>'
      }
    }
  ];

  public static moreMenuItem: IPage[] = [
    {
      page_name: 'More',
      page_additionalinfo: {
        page_bottommenuitemactiveelement:
          '<span style="border: 2px solid #9393F0; border-radius: 50%; background-color: #9393F0;">' +
          '<i class="fas fa-bars" style="font-size: large; align-self: center;"></i></span>',
        page_bottommenuitemnonactiveelement:
          '<span style="border: 2px solid #9393F0; border-radius: 50%;">' +
          '<i class="fal fa-bars" style="font-size: large; align-self: center;"></i>'
      }
    }
  ];

  public render() {
    return (
      <nav className="NavBar">
        <ul>
          {this.props.navItemList.map((item: IPage, index: number) => {
            return (
              <li key={index}>
                <NavLink key={index} to={process.env.PUBLIC_URL + '/' + item.page_name}>
                  <div className={'iconContainer ' + item.page_name}>
                    <span
                      className="active"
                      dangerouslySetInnerHTML={{
                        __html: item.page_additionalinfo?.page_bottommenuitemactiveelement
                      }}
                    />

                    <span
                      className="nonactive"
                      dangerouslySetInnerHTML={{
                        __html: item.page_additionalinfo?.page_bottommenuitemnonactiveelement
                      }}
                    />
                  </div>
                  {item.page_name}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}
