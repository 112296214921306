import IAsset from './IAsset';
import IPage from './IPage';

import LoadingIcon from '../components/LoadingIcon/LoadingIcon';
import Data from '../data/Data';

export default class API {
  public static Asset = class {
    public static AUDIO = 'audio';
    public static TEXT = 'text';
    public static PROGRAM = 'program';
  };

  public static FETCH_ERROR = 'No meditation available. Please check your internet connection.';

  public static domain = localStorage.getItem('API_DOMAIN'); // defined in  public/index.html

  public static assetUrl = 'api/assets'; // ?Page[page_name]=Meditate&Category[category_name]=Relax&page=1;
  public static pageAndCategoryUrl = 'api/pages';

  public static getAsset(assetId: number) {
    const promise = this.request(this.domain + this.assetUrl + '/' + assetId).then(response => {
      Data.assetList[assetId] = response;

      return response;
    }) as Promise<IAsset>;

    return promise;
  }

  public static getAssets(pageName: string, categoryName?: string): Promise<{ data: IAsset[] }> {
    let url = this.domain + this.assetUrl + '?';

    if (categoryName !== 'All') {
      url += 'Category[category_name]=' + categoryName;
    } else {
      url += 'Page[page_name]=' + pageName;
    }

    return this.request(url) as Promise<{ data: IAsset[] }>;
  }

  public static getPagesAndCategories(): Promise<{ data: IPage[] }> {
    const url = this.domain + this.pageAndCategoryUrl;

    return this.request(url) as Promise<{ data: IPage[] }>;
  }

  public static request(url: string, method = 'GET', data = null): Promise<any> {
    const init: any = {
      headers: { 'Content-Type': 'application/json' },
      method // *GET, POST, PUT, DELETE, etc.
    };
    if (data !== null) {
      init.body = JSON.stringify(data); // body data type must match "Content-Type" header
    }

    LoadingIcon.show();

    /*global fetch*/
    const promise = fetch(url, init)
      .then(response => {
        return response.json(); // parses JSON response into native Javascript objects
      })
      .finally(() => {
        LoadingIcon.hide();
      });

    return promise;
  }
}
