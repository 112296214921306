import * as React from 'react';

interface IProps {
  str: string;
}

export default class Lang extends React.Component<IProps, {}> {
  public render() {
    return (
      <span
        className="Lang"
        dangerouslySetInnerHTML={{ __html: this.addLangAttribute(this.props.str) }}
      />
    );
  }

  protected addLangAttribute(str: string): string {
    let result = '';
    let sectionStarted: boolean = false;

    if (str !== null) {
      for (let i = 0; i < str.length; i++) {
        const currentChar = str.charAt(i) as Char;

        if (this.isArabic(currentChar)) {
          if (!sectionStarted) {
            result += '<span lang="ar">' + currentChar;
            sectionStarted = true;
          } else {
            result += currentChar;
          }
        } else {
          if (sectionStarted) {
            result += currentChar + '</span>';
            sectionStarted = false;
          } else {
            result += currentChar;
          }
        }
      }
    }

    if (sectionStarted) {
      result += '</span>';
    }

    return result;
  }

  protected isArabic(char: Char) {
    let result: boolean;

    if (char.charCodeAt(0) >= 0x600 && char.charCodeAt(0) <= 0x6ff) {
      result = true;
    } else {
      result = false;
    }

    return result;
  }
}
