import * as React from 'react';

import Config from '../../config/Config';
import LoadingIconImage from './spinner2.gif';

import './LoadingIcon.css';

export default class LoadingIcon extends React.Component<any, any> {
  public static hide() {
    LoadingIcon.self.setState({ className: 'hide' });
  }

  public static show() {
    LoadingIcon.self.setState({ className: '' });
  }

  protected static self: LoadingIcon;

  constructor(props: any) {
    super(props);

    LoadingIcon.self = this;

    this.state = {
      className: 'hide'
    };
  }

  public render() {
    return (
      <img
        id="loading-icon"
        className={'centered ' + this.state.className}
        src={Config.appPath + LoadingIconImage}
        alt="loading icon"
      />
    );
  }
}
