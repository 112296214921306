import * as React from 'react';

import IAsset from '../../api/IAsset';
import IPage from '../../api/IPage';
import PageWithTitle from '../../components/PageWithTitle';
import AssetFileOperations from '../../data/AssetFileOperations';
import Data from '../../data/Data';
import FileStorage from '../../data/FileStorage/FileStorage';
import Html from '../../helpers/Html';
import More from './More';
import MoreItem from './MoreItem';

import './Downloads.css';

interface IState {
  [key: string]: number | null;
}
export default class Downloads extends MoreItem {
  constructor(props: any) {
    More.currentMenuItem = 'Downloads';

    super(props);

    this.delete = this.delete.bind(this);

    const state: IState = {
      all: null,
      usedBytes: null,
      grantedBytes: null
    };

    Data.getPagesAndCategories().forEach((page: IPage) => {
      state[page.page_name] = null;
    });

    this.state = state;
  }

  public render() {
    return (
      <div className="Downloads">
        <PageWithTitle title="Downloads">
          <dl>
            {Data.getPagesAndCategories().map((page: IPage, index) => {
              return (
                <div key={index}>
                  <dt onClick={this.delete} data-page-name={page.page_name}>
                    <span
                      className="icon"
                      dangerouslySetInnerHTML={{
                        __html: page.page_additionalinfo.page_nonactiveelement
                      }}
                    />
                    Delete {page.page_name} files
                  </dt>
                  <dd>{this.state[page.page_name]} MB</dd>
                </div>
              );
            })}

            <dt onClick={this.delete} data-page-name="all">
              <span className="icon">
                <i className="fal fa-trash" />
              </span>
              Delete all downloaded files
            </dt>
            <dd>{this.state.all !== null && this.state.all.toLocaleString()} KB</dd>
          </dl>

          <div className="text">
            Deleting files saves space on your device. You can always download them again.
          </div>

          <dl>
            <dt>Available Space</dt>
            <dd>
              {this.state.grantedBytes !== null &&
                (this.state.grantedBytes - this.state.usedBytes).toLocaleString()}{' '}
              MB
            </dd>
          </dl>
        </PageWithTitle>
      </div>
    );
  }

  public componentDidMount() {
    FileStorage.calculateAllDownloadedFileSize(totalSize => {
      this.setState({
        all: Math.ceil(totalSize / 1024)
      });
    }, this.errorCallback);

    FileStorage.getStorageSize((usedBytes: any, grantedBytes: any) => {
      this.setState({
        usedBytes: Math.ceil(usedBytes / 1024 / 1024),
        grantedBytes: Math.ceil(grantedBytes / 1024 / 1024)
      });
    }, this.errorCallback);
  }

  public shouldComponentUpdate(
    nextProps: Readonly<any>,
    nextState: Readonly<any>,
    nextContext: any
  ): boolean {
    const assetList = this.getAssetListObject();

    Object.keys(assetList).forEach((item, index) => {
      if (assetList[item] === undefined) {
        console.log('Downloads.shouldComponentUpdate(), undefined item = ', item);
        if (nextState[item] !== 0) {
          this.setState({ [item]: 0 });
        }
      } else {
        console.log('Downloads.shouldComponentUpdate(), item = ', item);
        AssetFileOperations.getAssetListSize(
          assetList[item],
          (totalSize: number) => {
            if (nextState[item] !== Math.ceil(totalSize / 1024 / 1024)) {
              this.setState({
                [item]: Math.ceil(totalSize / 1024 / 1024)
              });
            }
          },
          this.errorCallback
        );
      }
    });

    return true;
  }

  protected getAssetListObject(): { [key: string]: IAsset[] } {
    const offline = Html.replaceSpacesWithDashes(Data.OFFLINE_MENU_ITEM);

    const assetList: { [key: string]: IAsset[] } = {};

    Data.getPagesAndCategories().forEach((page: IPage) => {
      assetList[page.page_name] = Data.assetListPerCategory[page.page_name + '/' + offline];
    });

    return assetList;
  }

  protected delete(event: React.MouseEvent<HTMLSpanElement>) {
    const htmlElement = event.nativeEvent.target as HTMLElement;
    const pageName = htmlElement.dataset.pageName!;

    let fileList: Array<string | null> = [];

    if (window.confirm(htmlElement.innerText + '?')) {
      if (pageName === 'all') {
        FileStorage.listDirectory(fileList2 => {
          console.log(fileList2);
          this.deleteFileList(fileList2, pageName);
        });
      } else {
        const assetList = this.getAssetListObject()[pageName];
        fileList = AssetFileOperations.getFileList(assetList);

        this.deleteFileList(fileList, pageName);
      }
    }
  }

  protected deleteFileList(fileList: Array<string | null>, pageName: string) {
    fileList.forEach((fileName: string | null, index: number) => {
      if (fileName !== null) {
        FileStorage.deleteFile(fileName, () => {
          FileStorage.calculateAllDownloadedFileSize(totalSize => {
            this.setState({
              all: Math.ceil(totalSize / 1024)
            });
          }, this.errorCallback);
        });
      }

      if (index === fileList.length - 1) {
        const offline = Html.replaceSpacesWithDashes(Data.OFFLINE_MENU_ITEM);

        const state: { [key: string]: number } = {};
        if (pageName === 'all') {
          Data.getPagesAndCategories().forEach((page: IPage) => {
            Data.setAssetListPerCategory(page.page_name + '/' + offline, []);
            state[page.page_name] = 0;
          });
          this.setState(state);
        } else {
          Data.setAssetListPerCategory(pageName + '/' + offline, []);
          this.setState({ [pageName]: 0 });
        }
      }
    });
  }

  protected errorCallback(message: string, error: Error): void {
    console.log(message, error);

    if (error.name === 'FetchError') {
      alert('Error fetching remote files');
    } else if (error.name === 'FileSystemError') {
      alert('File System API is not supported');
    } else {
      alert('Error: ' + message);
    }
  }
}
