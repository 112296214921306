import * as React from 'react';

import Image from '../../components/Image';
import PageWithTitle from '../../components/PageWithTitle';
import More from './More';
import MoreItem from './MoreItem';

import GarmanLow from '../../assets/images/garman-low.jpg';

import './About.css';

export default class About extends MoreItem {
  constructor(props: any) {
    More.currentMenuItem = 'About';

    super(props);
  }

  public render() {
    return (
      <div className="About">
        <PageWithTitle title="About">
          <p>Dedicated to the memory of the person who brought me to Naqshbandi tariqa:</p>

          <h3>Garman Low</h3>

          <h5>FEBRUARY 1, 1973 – SEPTEMBER 21, 2019</h5>

          <Image imageUrl={GarmanLow} />
        </PageWithTitle>
      </div>
    );
  }
}
