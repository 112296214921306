import * as React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import API from '../api/API';
import IPage from '../api/IPage';
import MyHistory from '../components/MyHistory';
import NavBar from '../components/NavBar';
import Data from '../data/Data';
import Html from '../helpers/Html';
import Asset from './asset/Asset';
import Home from './home/Home';
import About from './more/About';
import Downloads from './more/Downloads';
import More from './more/More';
import Scenes from './more/Scenes/Scenes';
import Settings from './more/Settings';
import System from './more/System';
import PageWithTopNavBar from './PageWithTopNavBar';
import Program from './program/Program';

export default class Routing extends React.Component<{}, { navItemList: IPage[] }> {
  constructor(props: {}) {
    super(props);

    this.state = {
      navItemList: this.getNavItemList()
    };
  }

  public render() {
    return (
      <Router history={MyHistory}>
        <section className="Content">
          <Switch>
            <Route path={process.env.PUBLIC_URL + '/:pageName/Program/:id'} component={Program} />

            <Route path={process.env.PUBLIC_URL + '/Home'} component={Home} />

            <Route path={process.env.PUBLIC_URL + '/More/Scenes'} component={Scenes} />
            <Route path={process.env.PUBLIC_URL + '/More/Settings'} component={Settings} />
            <Route path={process.env.PUBLIC_URL + '/More/Downloads'} component={Downloads} />
            <Route path={process.env.PUBLIC_URL + '/More/System'} component={System} />
            <Route path={process.env.PUBLIC_URL + '/More/About'} component={About} />

            <Route
              path={process.env.PUBLIC_URL + '/More'}
              component={this.getMoreSubcomponentToRender()}
            />

            <Route path={process.env.PUBLIC_URL + '/Asset/:id'} component={Asset} />

            <Route path={process.env.PUBLIC_URL + '/:pageName'} component={PageWithTopNavBar} />
            <Route
              path={process.env.PUBLIC_URL + '/:pageName/:categoryName'}
              component={PageWithTopNavBar}
            />

            <Redirect to={process.env.PUBLIC_URL + '/Home'} />
          </Switch>

          <NavBar navItemList={this.state.navItemList} />
        </section>
      </Router>
    );
  }

  public componentDidMount() {
    API.getPagesAndCategories()
      .then(response => {
        Data.setPagesAndCategories(response.data);

        this.setState({ navItemList: this.getNavItemList() });
      })
      .catch((error: any) => {
        const offline = Html.replaceSpacesWithDashes(Data.OFFLINE_MENU_ITEM);

        const pages: IPage[] = Data.getPagesAndCategories();

        // we need to set Data.categoryListPerPage and Data.assetListPerCategory
        Data.setPagesAndCategories(pages);

        pages.forEach((page: IPage) => {
          PageWithTopNavBar.topNavBarIndex[page.page_name] = offline;
        });

        this.setState({ navItemList: this.getNavItemList() }); // to rerender with top Nav menus
      });
  }

  protected getMoreSubcomponentToRender() {
    let ComponentToRender: React.ComponentClass = More;

    if (More.currentMenuItem !== undefined) {
      ComponentToRender = this.getFromComponentRegistry(More.currentMenuItem);
      if (
        ComponentToRender === undefined ||
        // if in submenu of More and then click on More again then go to More
        '/More/' + More.currentMenuItem === MyHistory.previousUrl() ||
        MyHistory.previousUrl() === undefined
      ) {
        ComponentToRender = More;
      }
    }

    return ComponentToRender;
  }

  protected getFromComponentRegistry(componentName: string) {
    const componentRegistry: { [key: string]: React.ComponentClass } = {
      Scenes,
      Settings,
      Downloads,
      About
    };

    return componentRegistry[componentName];
  }

  protected getNavItemList(): IPage[] {
    let navItemList: IPage[];

    navItemList = NavBar.homeMenuItem
      .concat(Data.getPagesAndCategories())
      .concat(NavBar.moreMenuItem);

    return navItemList;
  }

  protected getPageList(): string[] {
    const result: string[] = [];

    Data.getPagesAndCategories().forEach(page => {
      result.push(page.page_name);
    });

    return result;
  }
}
