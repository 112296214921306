import * as React from 'react';

// import '@fortawesome/fontawesome-pro/css/all.css';
// require('typeface-nunito-sans');
import './App.css';

import BackgroundAudio from './components/BackgroundAudio/BackgroundAudio';
import LoadingIcon from './components/LoadingIcon/LoadingIcon';
import Config from './config/Config';
import Asset from './pages/asset/Asset';
import Routing from './pages/Routing';

class App extends React.Component {
  public render() {
    this.setTitle();

    return (
      <div className="App">
        <BackgroundAudio />
        <Asset />
        <LoadingIcon />

        <Routing />
      </div>
    );
  }

  public componentDidMount() {
    if (!Config.isCordova) {
      require('@fortawesome/fontawesome-pro/css/all.css');
      require('typeface-nunito-sans');
    }
  }

  protected setTitle() {
    let title = 'Sufi App';

    if (/\d+\.\d+\.\d+\.\d+/.test(window.location.hostname)) {
      title += ' (C9)';
    }

    document.title = title;
  }
}

export default App;
